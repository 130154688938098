import React from "react";
import { graphql } from "gatsby";
import Blog from "components/Blog";
import Layout from "utils/Layout";
import { constContent } from "staticContent/blog";
import { compilePostsData } from "helpers/blog.helpers";

const IndexPage = ({ data }) => {
  const posts = compilePostsData(data.allMdx.nodes, data.allWpPost.nodes);

  return (
    <Layout subTitle={constContent.newsletterPageTitle.substring(0, 10)}>
      <Blog posts={posts} onlyNewsletter={true} />
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query SITE_NEWSLETTER_QUERY {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {published: {eq: true}, tags: {eq: "Newsletter"}}}
    ) {
      nodes {
        frontmatter {
          title
          date
          author
          tags
          excerptImg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        id
        excerpt(pruneLength: 250)
        slug
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Newsletter"}}}}, status: {eq: "publish"}}
    ) {
      nodes {
        title
        date
        author {
          node {
            name
          }
        }
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        id
        excerpt
        slug
      }
    }
  }
`;
